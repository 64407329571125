import { TimeSlot } from '@service/availability.types'
import { D2DHoursCollection, DayOfWeek, SelfPickupHoursCollection } from '@service/configuration.types'
import { timeFormatWithMs } from '@util/config'
import { displayTimeSlot } from '@util/functions'
import { format, parse } from 'date-fns'
import { PickerTime } from 'src/types/Time'

export const createPickerTime = (start: string | Date, end: string | Date, isEnabled: boolean) =>
    convertTimeSlotToPickerTime([
        {
            time: {
                start: start instanceof Date ? format(start, timeFormatWithMs) : start,
                end: end instanceof Date ? format(end, timeFormatWithMs) : end,
            },
            isEnabled,
        },
    ])[0]

export const convertTimeSlotToPickerTime = (timeSlots: TimeSlot[]): PickerTime[] =>
    timeSlots?.map((x, index) => {
        return {
            key: index,
            start: x.time.start,
            end: x.time.end,
            startDate: parse(x.time.start, timeFormatWithMs, new Date()),
            endDate: parse(x.time.end, timeFormatWithMs, new Date()),
            displayTimeRange: `${displayTimeSlot(x.time.start)} - ${displayTimeSlot(x.time.end)}`,
            displayStartTime: displayTimeSlot(x.time.start),
            isEnabled: x.isEnabled,
        }
    })

export const convertBusinessHourD2DToPickerTime = (
    businessHours: D2DHoursCollection | undefined,
    date: Date,
): PickerTime[] => {
    if (!businessHours) return []
    const dayOfWeek = format(date, 'EEEE').toUpperCase() as DayOfWeek
    return businessHours[dayOfWeek]?.map((x, index) => ({
        key: index,
        start: x.start,
        end: x.end,
        startDate: parse(x.start, timeFormatWithMs, new Date()),
        endDate: parse(x.end, timeFormatWithMs, new Date()),
        displayTimeRange: `${displayTimeSlot(x.start)} - ${displayTimeSlot(x.end)}`,
        displayStartTime: displayTimeSlot(x.start),
        isEnabled: true,
    }))
}

export const convertBusinessHourSelfPickupToPickerTime = (
    businessHours: SelfPickupHoursCollection | undefined,
    date: Date,
): PickerTime[] => {
    if (!businessHours) return []
    const dayOfWeek = format(date, 'EEEE').toUpperCase() as DayOfWeek
    return businessHours[dayOfWeek]?.map((x, index) => ({
        key: index,
        start: x,
        startDate: parse(x, timeFormatWithMs, new Date()),
        displayTimeRange: displayTimeSlot(x),
        displayStartTime: displayTimeSlot(x),
        isEnabled: true,
    }))
}
