import { GlobalNoLayoutStyle } from '@assets/styles/noLayoutStyles'
import { AppProps } from 'next/app'
import Head from 'next/head'
import { QueryClientProvider, QueryClient } from 'react-query'
import { ReactQueryDevtools } from 'react-query-devtools'

export const AppWithoutLayout = ({ Component, pageProps }: AppProps) => {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchInterval: false,
            },
        },
    })

    return (
        <>
            <Head>
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0" />
                <title>Swapp</title>
            </Head>
            <GlobalNoLayoutStyle />
            <QueryClientProvider client={queryClient}>
                <ReactQueryDevtools initialIsOpen={false} />
                <Component {...pageProps} />
            </QueryClientProvider>
        </>
    )
}
