import { addDays, differenceInCalendarDays, format } from 'date-fns'
import {
    useState,
    createContext,
    useContext,
    Dispatch,
    SetStateAction,
    useEffect,
    useReducer,
    ReactNode,
    useMemo,
    useCallback,
} from 'react'
import { CitySettingsResponse } from '@service/configuration.types'
import { useLocaleConfig } from './config'
import { defaultCityCode, defaultOpenHoursStartTime, nextDayDeliveryLimits } from '@util/config'
import { useRouter } from 'next/router'
import isTomorrow from 'date-fns/isTomorrow'
import { Address } from '@service/booking.types'
import { parseTime } from '@util/functions'
import { configurationService } from '@service/configuration'
import useMountEffect from '@hooks/useMountEffect'
import { DesktopDatePickerOverlayType } from '../util/enums'
import { PickerTime } from 'src/types/Time'
import { useBusinessHours } from '@hooks/useBusinessHours'
import { createPickerTime } from '@components/global/TimeSelector/utils'

type SearchContextTypes = {
    minRentalPeriod: number

    // Date part
    fromDate: Date | null
    toDate: Date | null
    setFromDate: Dispatch<SetStateAction<Date | null>>
    setToDate: Dispatch<SetStateAction<Date | null>>
    closedDates: Date[]
    setClosedDates: Dispatch<SetStateAction<Date[]>>

    // Time part
    fromTime: Date | null
    toTime: Date | null
    setFromTime: Dispatch<SetStateAction<Date | null>>
    setToTime: Dispatch<SetStateAction<Date | null>>
    fromPickerTime?: PickerTime
    toPickerTime?: PickerTime
    setFromPickerTime: Dispatch<SetStateAction<PickerTime | undefined>>
    setToPickerTime: Dispatch<SetStateAction<PickerTime | undefined>>

    page: number
    setPage: Dispatch<SetStateAction<number>>
    pageSize: number
    setPageSize: Dispatch<SetStateAction<number>>

    cityCode: string
    setCityCode: Dispatch<SetStateAction<string>>

    timeOptions: Date[]

    datePickerOverlay: boolean
    setDatepickerOverlay: Dispatch<SetStateAction<boolean>>

    address: AddressState
    addressDispatch: Dispatch<AddressAction>

    redirectUrl: string | undefined
    setRedirectUrl: Dispatch<SetStateAction<string | undefined>>

    citySettings: CitySettingsResponse | undefined
    onClickedTime: Date | undefined
    setOnClickedTime: Dispatch<SetStateAction<Date | undefined>>
    setTimeOptions: Dispatch<SetStateAction<Date[] | []>>
    desktopDatePickerOverlay: DesktopDatePickerOverlay
    setDesktopDatePickerOverlay: Dispatch<SetStateAction<DesktopDatePickerOverlay>>
    initializationByCitySettings: (initDate?: boolean) => void
}

export type DesktopDatePickerOverlay =
    | DesktopDatePickerOverlayType.DELIVERY
    | DesktopDatePickerOverlayType.RETURN
    | DesktopDatePickerOverlayType.NONE

export const defaultFromDate = addDays(new Date(), 1)
export const defaultToDate = addDays(new Date(), 2)

export const SearchContext = createContext<SearchContextTypes | null>(null)

export const SearchProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const { query } = useRouter()
    const { from, to } = query

    const now = new Date()
    const { citySettings } = useLocaleConfig()
    const [minRentalPeriod, setMinRentalPeriod] = useState(1)
    const [toDateBasedOnMinRentalPeriod, setToDateBasedOnMinRentalPeriod] = useState<Date>(addDays(new Date(), 1))

    const datepickerDisabledDates = useMemo<Date[]>(() => {
        return citySettings?.holidays ? citySettings?.holidays.map((x) => new Date(x)) : []
    }, [citySettings?.holidays])

    const initFromDate = useMemo(
        () =>
            from &&
            differenceInCalendarDays(new Date(from as string), defaultFromDate) >= 0 &&
            new Date(from as string),
        [from],
    )

    // Date part
    const [fromDate, setFromDate] = useState<Date | null>(initFromDate || defaultFromDate)
    const [toDate, setToDate] = useState<Date | null>(toDateBasedOnMinRentalPeriod)
    const [closedDates, setClosedDates] = useState<Date[]>(datepickerDisabledDates)

    // Time part
    const [fromTime, setFromTime] = useState<Date | null>(null)
    const [fromPickerTime, setFromPickerTime] = useState<PickerTime>()
    const [toTime, setToTime] = useState<Date | null>(null)
    const [toPickerTime, setToPickerTime] = useState<PickerTime>()
    const [timeOptions, setTimeOptions] = useState<Date[]>([])

    const [page, setPage] = useState(0)
    const [pageSize, setPageSize] = useState(10)
    const [cityCode, setCityCode] = useState(citySettings?.cityCode || defaultCityCode)

    const [datePickerOverlay, setDatepickerOverlay] = useState<boolean>(false)
    const [redirectUrl, setRedirectUrl] = useState<string | undefined>()
    const [desktopDatePickerOverlay, setDesktopDatePickerOverlay] = useState<DesktopDatePickerOverlay>(
        DesktopDatePickerOverlayType.NONE,
    )

    const [onClickedTime, setOnClickedTime] = useState<Date | undefined>()
    const { businessHoursForD2D: businessHoursForD2DFrom } = useBusinessHours({ date: fromDate || undefined })
    const { businessHoursForD2D: businessHoursForD2DTo } = useBusinessHours({ date: toDate || undefined })

    const currentHours = now.getHours()

    const fromDateIsTomorrow = useMemo(() => {
        if (fromDate) return isTomorrow(fromDate)
        return null
    }, [fromDate])

    const setPickerTimesWithDefaults = useCallback(
        (initFromTimeString: string, initToTimeString: string) => {
            if (!businessHoursForD2DFrom || !businessHoursForD2DTo) return

            const defaultBusinessTimeSlotFrom = businessHoursForD2DFrom.find(
                (x) => x.start === defaultOpenHoursStartTime,
            )

            const defaultBusinessTimeSlotTo = businessHoursForD2DTo.find((x) => x.start === defaultOpenHoursStartTime)
            if (defaultBusinessTimeSlotFrom?.end && defaultBusinessTimeSlotFrom?.start && !fromPickerTime) {
                setFromPickerTime(createPickerTime(initFromTimeString, defaultBusinessTimeSlotFrom.end, true))
            }
            if (defaultBusinessTimeSlotTo?.end && defaultBusinessTimeSlotTo?.start && !toPickerTime) {
                setToPickerTime(createPickerTime(initToTimeString, defaultBusinessTimeSlotTo.end, true))
            }
        },
        [businessHoursForD2DFrom, businessHoursForD2DTo, fromPickerTime, toPickerTime],
    )

    const initializationByCitySettings = useCallback(
        (initDate = false) => {
            const now = new Date()
            const fromTimeFromUrl = from && format(new Date(from as string), 'HH:mm:ss')
            const toTimeFromUrl = to && format(new Date(to as string), 'HH:mm:ss')
            // Default from and to time
            const fromTimeString = fromTimeFromUrl || defaultOpenHoursStartTime
            const beforeLimitFromTimeString =
                currentHours <= nextDayDeliveryLimits.todayCurrentHoursLimit
                    ? fromTimeString
                    : `${nextDayDeliveryLimits.nextDayNoonHours}:00:00`

            const initFromTimeString = fromDateIsTomorrow ? beforeLimitFromTimeString : fromTimeString
            const initToTimeString = toTimeFromUrl ?? defaultOpenHoursStartTime
            const initFromTime = parseTime(now, initFromTimeString)
            const initToTime = parseTime(now, initToTimeString)

            if (initDate) {
                setFromDate(initFromDate || defaultFromDate)
                setToDate(toDateBasedOnMinRentalPeriod)
            }
            !fromTime && setFromTime(initFromTime)
            !toTime && setToTime(initToTime)

            setPickerTimesWithDefaults(initFromTimeString, initToTimeString)
        },
        [
            currentHours,
            from,
            fromDateIsTomorrow,
            fromTime,
            initFromDate,
            setPickerTimesWithDefaults,
            to,
            toDateBasedOnMinRentalPeriod,
            toTime,
        ],
    )

    useMountEffect(() => {
        async function getMinRentalPeriod() {
            return await configurationService.getCitySettings(cityCode)
        }
        getMinRentalPeriod().then((data) => {
            if (data?.data?.minRentalPeriod) {
                setMinRentalPeriod(data.data.minRentalPeriod)
            }
        })
    })

    useEffect(() => {
        const toDate = addDays(initFromDate || defaultFromDate, minRentalPeriod)
        setToDateBasedOnMinRentalPeriod(toDate)
        setToDate(toDate)
        // FIXME - incorrect deps
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [minRentalPeriod])

    useEffect(() => {
        initializationByCitySettings()
    }, [initializationByCitySettings])

    const [address, addressDispatch] = useReducer(addressReducer, {
        handoverAddress: null,
        handbackAddress: null,
    })

    return (
        <SearchContext.Provider
            value={{
                minRentalPeriod,
                fromDate,
                toDate,
                setFromDate,
                setToDate,
                fromTime,
                toTime,
                setFromTime,
                setToTime,
                fromPickerTime,
                toPickerTime,
                setFromPickerTime,
                setToPickerTime,
                page,
                setPage,
                pageSize,
                setPageSize,
                cityCode,
                setCityCode,
                timeOptions,
                datePickerOverlay,
                setDatepickerOverlay,
                address,
                addressDispatch,
                redirectUrl,
                setRedirectUrl,
                citySettings,
                onClickedTime,
                setOnClickedTime,
                setTimeOptions,
                closedDates,
                setClosedDates,
                desktopDatePickerOverlay,
                setDesktopDatePickerOverlay,
                initializationByCitySettings,
            }}
        >
            {children}
        </SearchContext.Provider>
    )
}

export const useSearch = (): SearchContextTypes => useContext(SearchContext) as SearchContextTypes

export enum AddressType {
    HANDOVER_ADDRESS = 'handoverAddress',
    HANDBACK_ADDRESS = 'handbackAddress',
    TEMPORARY_HANDOVER_ADDRESS = 'handoverAddressTemporary',
    TEMPORARY_HANDBACK_ADDRESS = 'handbackAddressTemporary',
}

export type AddressState = {
    handoverAddress?: Address | null
    handbackAddress?: Address | null
    handoverAddressTemporary?: Address | null
    handbackAddressTemporary?: Address | null
}

export enum AddressActionKind {
    RESET = 'reset',
    COPY = 'copy',
    COPYTEMP = 'copytemp',
    SET = 'set',
}

export type AddressAction =
    | {
          type: AddressActionKind.COPY
          reverse?: boolean
      }
    | {
          type: AddressActionKind.COPYTEMP
          reverse?: boolean
      }
    | {
          type: AddressActionKind.RESET
      }
    | {
          type: AddressActionKind.SET
          value: any
          name: keyof AddressState | AddressType.HANDOVER_ADDRESS | AddressType.HANDBACK_ADDRESS
      }

const addressReducer = (state: AddressState, action: AddressAction): AddressState => {
    if (action.type === AddressActionKind.COPY) {
        if (action.reverse) return { ...state, handoverAddress: state.handbackAddress }
        return { ...state, handbackAddress: state.handoverAddress }
    }
    if (action.type === AddressActionKind.COPYTEMP) {
        if (action.reverse) return { ...state, handoverAddressTemporary: state.handbackAddressTemporary }
        return { ...state, handbackAddressTemporary: state.handoverAddressTemporary }
    }
    if (action.type === AddressActionKind.RESET) {
        return {
            handoverAddress: null,
            handbackAddress: null,
            handoverAddressTemporary: null,
            handbackAddressTemporary: null,
        }
    }

    if (action.type === AddressActionKind.SET) {
        return { ...state, [action?.name]: action.value }
    }

    return state
}
