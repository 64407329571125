import { ProductType } from '@service/configuration.types'
import { defaultDelivery, defaultProductType } from '@util/config'
import { AddressModalType, DateTimeSelectorTypes } from '@util/enums'
import { useRouter } from 'next/router'
import { createContext, useContext, ReactNode, useReducer, Dispatch, useMemo } from 'react'

type BookingState = {
    deliveryType: string
    fromDateWarning: boolean
    toDateWarning: boolean
    productType: ProductType
    addressModalType: AddressModalType
    dateModalType: 'startDate' | 'endDate' | 'none'
    isFiltering: boolean
    bookingLoader: boolean
    slideUpModalOpen: boolean
    isFromTimeSlotBlocked: boolean
    isToTimeSlotBlocked: boolean
}

type GlobalContextTypes = {
    bookingState: BookingState
    bookingDispatch: Dispatch<BookingAction>
    listingId: number | string
    bookingId: number | string
}

export const GlobalContext = createContext<GlobalContextTypes | null>(null)

export enum BookingActionKind {
    CHANGE_DELIVERY_TYPE = 'CHANGE_DELIVERY_TYPE',
    TOGGLE_FROM_DATE_WARNING = 'TOGGLE_FROM_DATE_WARNING',
    TOGGLE_TO_DATE_WARNING = 'TOGGLE_TO_DATE_WARNING',
    CHANGE_PRODUCT_TYPE = 'CHANGE_PRODUCT_TYPE',
    CHANGE_ADDRESSMODAL_TYPE = 'CHANGE_ADDRESSMODAL_TYPE',
    TOGGLE_FILTERING = 'TOGGLE_FILTERING',
    TOGGLE_BOOKING_LOADER = 'TOGGLE_BOOKING_LOADER',
    CHANGE_DATEMODAL_TYPE = 'CHANGE_DATEMODAL_TYPE',
    TOGGLE_SLIDE_UP_MODAL = 'TOGGLE_SLIDE_UP_MODAL',
    SET_IS_FROM_TIMESLOT_BLOCKED = 'SET_IS_FROM_TIMESLOT_BLOCKED',
    SET_IS_TO_TIMESLOT_BLOCKED = 'SET_IS_TO_TIMESLOT_BLOCKED',
}

const INITIAL_STATE = {
    deliveryType: defaultDelivery,
    fromDateWarning: false,
    toDateWarning: false,
    productType: defaultProductType,
    addressModalType: AddressModalType.none,
    dateModalType: 'none',
    isFiltering: false,
    bookingLoader: false,
    slideUpModalOpen: false,
    isFromTimeSlotBlocked: false,
    isToTimeSlotBlocked: false,
}

const BookingReducer = (state: BookingState, action: any) => {
    switch (action.type) {
        case BookingActionKind.CHANGE_DELIVERY_TYPE:
            return { ...state, deliveryType: action.value }
        case BookingActionKind.TOGGLE_FROM_DATE_WARNING:
            return { ...state, fromDateWarning: action.value }
        case BookingActionKind.TOGGLE_TO_DATE_WARNING:
            return { ...state, toDateWarning: action.value }
        case BookingActionKind.CHANGE_PRODUCT_TYPE:
            return { ...state, productType: action.value }
        case BookingActionKind.CHANGE_ADDRESSMODAL_TYPE:
            return { ...state, addressModalType: action.value }
        case BookingActionKind.TOGGLE_FILTERING:
            return { ...state, isFiltering: action.value }
        case BookingActionKind.TOGGLE_BOOKING_LOADER:
            return { ...state, bookingLoader: action.value }
        case BookingActionKind.CHANGE_DATEMODAL_TYPE:
            return { ...state, dateModalType: action.value }
        case BookingActionKind.TOGGLE_SLIDE_UP_MODAL:
            return { ...state, slideUpModalOpen: action.value }
        case BookingActionKind.SET_IS_FROM_TIMESLOT_BLOCKED:
            return { ...state, isFromTimeSlotBlocked: action.value }
        case BookingActionKind.SET_IS_TO_TIMESLOT_BLOCKED:
            return { ...state, isToTimeSlotBlocked: action.value }
        default:
            return state
    }
}

export const GlobalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const { query } = useRouter()
    const [bookingState, bookingDispatch] = useReducer(BookingReducer, INITIAL_STATE)

    const listingId = useMemo(() => query?.listingsId as string, [query?.listingsId])
    const bookingId = useMemo(() => query?.bookingId as string, [query?.bookingId])

    return (
        <GlobalContext.Provider
            value={{
                bookingState,
                bookingDispatch,
                listingId,
                bookingId,
            }}
        >
            {children}
        </GlobalContext.Provider>
    )
}

export const useGlobalState = (): GlobalContextTypes => useContext(GlobalContext) as GlobalContextTypes

export type BookingAction = {
    type: BookingActionKind
    value: string | boolean
}

export const ToggleSlideUpModal = (value: boolean) => ({ type: BookingActionKind.TOGGLE_SLIDE_UP_MODAL, value })
export const SetTimSlotBlocking = (value: boolean, specTime: DateTimeSelectorTypes) => ({
    type:
        specTime === DateTimeSelectorTypes.from
            ? BookingActionKind.SET_IS_FROM_TIMESLOT_BLOCKED
            : BookingActionKind.SET_IS_TO_TIMESLOT_BLOCKED,
    value,
})
