import { CityCode, DayOfWeek } from './enums'

export const DAY_OF_WEEK = [
    DayOfWeek.sunday,
    DayOfWeek.monday,
    DayOfWeek.tuesday,
    DayOfWeek.wednesday,
    DayOfWeek.thursday,
    DayOfWeek.friday,
    DayOfWeek.saturday,
]

export const REFUND = 'refund'
export const CHARGE = 'charge'
export const DOOR_TO_DOOR = 'DOOR_TO_DOOR'
export const SELF_PICKUP = 'SELF_PICKUP'
export const OTHER = 'Other'
export const AED = 'AED'
export const PASSWORD_LENGTH = 6
export const EMAIL_REGEX =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const PHONE_REGEX = /^(\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
export const PHONE_REGEX_COMPLIANT = /^[0-9-+ ]+$/
export const NOT_SPECIAL_REGEX = /^[^!+%/*#><?/,._@&=]+$/

export const NUMBER_OF_MONTHS = 12
export const BIRTHYEAR_RANGE = 100
export const CURRENT_YEAR = new Date().getFullYear()
export const dateToStoreTheTime = '0001/01/01'
export const FIRST_MONTH_OF_YEAR_STRING = '1'
export const FIRST_DAY_OF_MONTH_STRING = '1'
export const DEFAULT_COUNTRY_CODE = '971'
export const MONTHLY_SERVICE_FEE_DISPLAY = [CityCode.DXB, CityCode.AUH]

export const DATE_DELIMITER_STANDARD = ','
export const DATE_DELIMITER_AR = '،'

export const QA_JWT =
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJpZGVudGl0eS1zZXJ2aWNlQHFhLmpvaW5zd2FwcC5jb20iLCJhdWQiOiJxYS5qb2luc3dhcHAuY29tIiwicm9sZXMiOlsiUk9MRV9DVVNUT01FUiJdLCJzd2FwcFVzZXJJZCI6IjQwZWIxYjA4LWMwYjMtNDEzMi04NTc0LTk5ZGFhMmMwMjkyMiIsImV4cCI6MTc0ODc3MTIwMCwiaWF0IjoxNzQzMDM2NDg2LCJpc3MiOiJxYS5qb2luc3dhcHAuY29tIiwiY2FyZWVtSWQiOiIwLmY4a0ViY2dseDlzZDlJYmx3Q0lwMFE9PSIsImVtYWlsIjoiMC5mOGtFYmNnbHg5c2Q5SWJsd0NJcDBRPT1Aam9pbnN3YXBwLmNvbSJ9.7qZ92r-4e0dMFmxoknU8XctPoDiP8KC1dCybsVASrIM'
export const DEV_JWT =
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJpZGVudGl0eS1zZXJ2aWNlQGRldi5qb2luc3dhcHAuY29tIiwiYXVkIjoiZGV2LmpvaW5zd2FwcC5jb20iLCJyb2xlcyI6WyJST0xFX0NVU1RPTUVSIl0sInN3YXBwVXNlcklkIjoiZmZjMjA3MzktZjlhMC00YmVhLThhNDYtZDFmM2Q0OTQwODdiIiwiZXhwIjoxNzQ4NzcxMjAwLCJpYXQiOjE2NDMwMzY0ODYsImlzcyI6ImRldi5qb2luc3dhcHAuY29tIiwiY2FyZWVtSWQiOiIwLmY4a0ViY2dseDlzZDlJYmx3Q0lwMFE9PSIsImVtYWlsIjoiMC5mOGtFYmNnbHg5c2Q5SWJsd0NJcDBRPT1Aam9pbnN3YXBwLmNvbSJ9.rn5MDFYRhUEPEJFZDBKJWYdPLRS2qL2Ydp_Tj0CVjqE'
