import { ProductType } from '@service/configuration.types'
import { ReactNode, createContext, useCallback, useContext } from 'react'
import { BookingActionKind, useGlobalState } from './global'

type CommonContextType = {
    changeProductTo: (productType: ProductType) => void
}

const CommonContext = createContext<CommonContextType>({
    changeProductTo: () => null,
})

export const CommonProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const { bookingDispatch } = useGlobalState()

    const changeProductTo = useCallback(
        (productType: ProductType) => {
            if (!productType) return

            bookingDispatch({
                type: BookingActionKind.CHANGE_PRODUCT_TYPE,
                value: productType,
            })
        },
        [bookingDispatch],
    )

    return (
        <CommonContext.Provider
            value={{
                changeProductTo,
            }}
        >
            {children}
        </CommonContext.Provider>
    )
}

export function useCommon() {
    return useContext(CommonContext)
}
