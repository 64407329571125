import { Currency, ProductType } from '@service/configuration.types'
import { DOOR_TO_DOOR } from './constants'
import { format } from 'date-fns'
import { ar } from 'date-fns/locale'
import { City, CityCode, CountryCode, LanguageISOCode, LanguageUtsCode, ServerLanguageCode } from './enums'

type JitsuLogLevel = 'DEBUG' | 'INFO' | 'WARN' | 'ERROR' | 'NONE'

type Config = {
    ENV: string
    VERSION: string
    GA_TRACKING_ID: string
    GA_TRACKING_ID_QA: string
    GA_ADS_TRACKING_ID: string
    GMAPS_API_KEY: string
    BASE_URL: string
    IMAGEX_URL: string
    IMAGIN_STUDIOS_BASE_URL: string
    FCWIDGET_TOKEN: string
    BYPASS_CAREEM_PAY: boolean
    CAREEM_PAY_APP_URI: string
    PRODUCT_SELECTOR_LINK: string
    JITSU_CLIENT_DATA_KEY: string
    JITSU_SERVER_DATA_KEY: string
    JITSU_TRACKING_HOST: string
    GROWTHBOOK_ENDPOINT: string
    JITSU_LOG_LEVEL: JitsuLogLevel
    FACEBOOK_APP_ID: string
    GOOGLE_APP_ID: string
    CAREEM_SETTINGS_IOS_DEEPLINK: string
    CAREEM_SETTINGS_ANDROID_DEEPLINK: string
    CHECKOUT_API_KEY: string
}

export const config: Config = {
    // Runtime variables
    ENV: process.env.NODE_ENV || 'development',
    VERSION: process.env.APP_VERSION || 'no-version',
    // Build time vairables
    GA_TRACKING_ID: process.env.NEXT_PUBLIC_GA_TRACKING_ID || '',
    GA_TRACKING_ID_QA: process.env.NEXT_PUBLIC_GA_TRACKING_ID_QA || '',
    GA_ADS_TRACKING_ID: process.env.NEXT_PUBLIC_GOOGLE_ADS_ID || '',
    GMAPS_API_KEY: process.env.NEXT_PUBLIC_GMAPS_API_KEY || '',
    BASE_URL: process.env.NEXT_PUBLIC_BASE_URL || 'https://dev.joinswapp.com',
    IMAGEX_URL: process.env.NEXT_PUBLIC_IMAGEX_URL || 'https://dev-swapp-rental.imgix.net',
    IMAGIN_STUDIOS_BASE_URL: process.env.NEXT_PUBLIC_IMAGIN_STUDIOS_BASE_URL || 'https://cdn.imagin.studio',
    FCWIDGET_TOKEN: process.env.NEXT_PUBLIC_FCWIDGET_TOKEN || '93a44544-1a9e-4fc8-b86b-5904027bd6db',
    BYPASS_CAREEM_PAY: process.env.NEXT_PUBLIC_BYPASS_CAREEM_PAY
        ? process.env.NEXT_PUBLIC_BYPASS_CAREEM_PAY === 'true'
        : false,
    CAREEM_PAY_APP_URI: process.env.NEXT_PUBLIC_CAREEM_PAY_APP_URI || 'careem://app.careem.com/pay',
    PRODUCT_SELECTOR_LINK:
        process.env.NEXT_PUBLIC_PRODUCT_SELECTOR_LINK ||
        'https://identity.qa.careem-engineering.com/authorize?client_id=0a2d2f09-97cb-40e8-8e53-bfabc36db36f&redirect_uri=https://dev.joinswapp.com/auth/careem/callback%3Ftarget%3DproductSelector&response_type=code',
    JITSU_CLIENT_DATA_KEY: process.env.NEXT_PUBLIC_JITSU_CLIENT_DATA_KEY || '',
    JITSU_SERVER_DATA_KEY: process.env.JITSU_SERVER_DATA_KEY || '',
    JITSU_TRACKING_HOST: process.env.NEXT_PUBLIC_JITSU_TRACKING_HOST || '',
    GROWTHBOOK_ENDPOINT:
        process.env.NEXT_PUBLIC_GROWTHBOOK_ENDPOINT ||
        'https://cdn.growthbook.io/api/features/dev_fr4bv99RVQOFPERsaRMzmBilTfikiA3MqvChNVzIw9I',
    JITSU_LOG_LEVEL: (process.env.NEXT_PUBLIC_JITSU_LOG_LEVEL as JitsuLogLevel) || 'NONE',
    FACEBOOK_APP_ID: process.env.NEXT_PUBLIC_FACEBOOK_APP_ID || '',
    GOOGLE_APP_ID: process.env.NEXT_PUBLIC_GOOGLE_APP_ID || '',
    CAREEM_SETTINGS_IOS_DEEPLINK: process.env.NEXT_CAREEM_SETTINGS_IOS_DEEPLINK || 'careem://settings',
    CAREEM_SETTINGS_ANDROID_DEEPLINK:
        process.env.NEXT_CAREEM_SETTINGS_ANDROID_DEEPLINK || 'careem://home.careem.com/settings',
    CHECKOUT_API_KEY: process.env.NEXT_PUBLIC_CHECKOUT_API_KEY || '',
}

export const basePath = 'rental'
export const isDev = config.ENV === 'development'
export const isProd = config.ENV === 'production'
export const IS_QA_ENV = process.env.NEXT_PUBLIC_ENV === 'qa'
export const isTest = config.ENV === 'test'
export const isMock =
    process.env.NEXT_PUBLIC_IS_MOCK !== undefined ? process.env.NEXT_PUBLIC_IS_MOCK.toLowerCase() === 'true' : false
export const isCi = process.env.CI || false

// Nextjs stuff
export const isServer = typeof window === 'undefined'

// Further hard-coded constants
export const defaultDubaiCoords = {
    lat: 25.2048, // Middle of Dubai
    lng: 55.2708,
}
export const svgLocation = `/${basePath}/svg/`
export const defaultCity = City.dubai
export const defaultCityCode = CityCode.DXB
export const defaultCountry = CountryCode.ae
export const defaultCurrency = Currency.AED
export const defaultServerLanguage = ServerLanguageCode.EN
export const defaultLocale = LanguageUtsCode.enUS
export const defaultOpenHoursStartTime = '14:00:00'
export const defaultOpenHoursEndTime = '18:00:00'
export const defaultProductType = ProductType.DAILY
export const timeSlotHours = 2
export const maximumD2DTimePeriodEnding = 18
export const highlightBookingsCount = 3 // 0 is all bookings
export const placeholderAvatarImage = 'placeholder-avatar.jpg'
export const cookieName = 'swapp_auth_jwt'
export const verificationFlowAfterBooking = true
export const disabledHomePage = true
export const defaultDelivery = DOOR_TO_DOOR

export const nextDayDeliveryLimits = {
    todayCurrentHoursLimit: 16,
    nextDayBusinessHoursStart: 10,
    nextDayNoonHours: 14,
}

export const formatPrice = (price: number) => new Intl.NumberFormat().format(price)
export const isRTLLocale = (locale?: string): boolean => {
    return locale?.startsWith('ar') ?? false
}
export const rtlDateFormat = 'EEE. d MMM'
export const ltrDateFormat = 'EEE, MMM d'
export const formatDate = (date?: Date | string | number | null, isRTL = false): string => {
    if (!date) {
        return ''
    }
    if (typeof date === 'string') {
        date = new Date(date)
    }
    if (typeof date === 'number') {
        date = new Date(date)
    }
    if (isNaN(date.getTime())) {
        return ''
    }
    if (isRTL) {
        return format(date, rtlDateFormat, { locale: ar })
    }

    return format(date, ltrDateFormat)
}
export const timeFormat = 'HH:mm'
export const timeFormatWithMs = 'HH:mm:ss'

interface CitySettings {
    supplierEmail: string
}

export const citySettings: Record<CityCode, CitySettings> = {
    [CityCode.DXB]: {
        supplierEmail: 'suppliers.dubai@joinswapp.com',
    },
    [CityCode.RUH]: {
        supplierEmail: 'suppliers.riyadh@joinswapp.com',
    },
    [CityCode.AUH]: {
        supplierEmail: 'suppliers.abudhabi@joinswapp.com',
    },
    [CityCode.BUD]: {
        supplierEmail: 'suppliers.budapest@joinswapp.com',
    },
    [CityCode.VIE]: {
        supplierEmail: 'suppliers.vienna@joinswapp.com',
    },
}

// FreshChat config
export const FRESHCHAT_HOST = 'https://joinswapp-org.freshchat.com'
interface WidgetConfig {
    city: City
    productType: ProductType
    language: LanguageISOCode
    widgetGuid: string
}
const FRESHCHAT_WIDGET_GUID: WidgetConfig[] = [
    {
        city: City.riyadh,
        productType: ProductType.DAILY,
        language: LanguageISOCode.en,
        widgetGuid: '4b797476-aeaf-40b2-ad9b-01748847c048',
    },
    {
        city: City.riyadh,
        productType: ProductType.DAILY,
        language: LanguageISOCode.ar,
        widgetGuid: 'f5275f96-996c-4f5c-8d1d-20ee4c7e2ad9',
    },
    {
        city: City.riyadh,
        productType: ProductType.MONTHLY,
        language: LanguageISOCode.ar,
        widgetGuid: '33a3a224-81b4-46aa-97e5-c61c25127c45',
    },
    {
        city: City.riyadh,
        productType: ProductType.MONTHLY,
        language: LanguageISOCode.en,
        widgetGuid: '8fce37d3-7590-4e6d-936d-f8547a9c664c',
    },
]
export const getFreshchatWidgetByCityProductAndLanguage = (city: City, productType: ProductType, locale: string) => {
    return FRESHCHAT_WIDGET_GUID.find(
        (c) => c.city === city && c.productType === productType && locale.startsWith(c.language),
    )?.widgetGuid
}
export const FRESHCHAT_DEFAULT_WIDGET_GUID = getFreshchatWidgetByCityProductAndLanguage[City.dubai]

// https://crmsupport.freshworks.com/en/support/solutions/articles/50000004407-localize-the-freshchat-messenger-interface?cloud=freshchat
export const FRESHCHAT_WIDGET_LANGUAGE_BY_LANGUAGE_ISO_CODE = {
    [LanguageISOCode.ar]: 'ar',
    [LanguageISOCode.en]: 'en-us',
}
export const FRESHCHAT_DEFAULT_WIDGET_LANGUAGE = FRESHCHAT_WIDGET_LANGUAGE_BY_LANGUAGE_ISO_CODE[LanguageISOCode.en]
