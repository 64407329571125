import Script from 'next/script'
import { IS_QA_ENV, config } from '@util/config'
import { AvailableTrackingScripts, FacebookEventType } from '@util/enums'

type Props = {
    enabledTrackingScripts: AvailableTrackingScripts[]
}

export const TrackingScripts = ({ enabledTrackingScripts }: Props) => (
    <>
        {enabledTrackingScripts.includes(AvailableTrackingScripts.GA) && (
            <>
                <Script
                    src={`https://www.googletagmanager.com/gtag/js?id=${
                        IS_QA_ENV ? config.GA_TRACKING_ID_QA : config.GA_TRACKING_ID
                    }`}
                />
                <Script id="ga_tracking">
                    {`
                                window.dataLayer = window.dataLayer || [];
                                function gtag(){dataLayer.push(arguments);}
                                gtag('js', new Date());
                                gtag('config', '${IS_QA_ENV ? config.GA_TRACKING_ID_QA : config.GA_TRACKING_ID}', {
                                    page_path: window.location.pathname,
                                });
                                gtag('config', '${IS_QA_ENV ? config.GA_TRACKING_ID_QA : config.GA_TRACKING_ID}', {
                                    page_path: window.location.pathname,
                                });
                `}
                </Script>
            </>
        )}
        {enabledTrackingScripts.includes(AvailableTrackingScripts.HOTJAR) && (
            <>
                <Script id="hotjar_tracking">
                    {`
                                (function(h,o,t,j,a,r){
                                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                                h._hjSettings={hjid:2238223,hjsv:6};
                                a=o.getElementsByTagName('head')[0];
                                r=o.createElement('script');r.async=1;
                                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                                a.appendChild(r);
                                })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
                `}
                </Script>
                <Script id="hotjar_user">
                    {`
                    var userId = null;
                    window.hj('identify', userId, {
                        'product': 'daily'
                    });
         `}
                </Script>
            </>
        )}
        {enabledTrackingScripts.includes(AvailableTrackingScripts.FACEBOOK) && (
            <>
                <Script id="facebook_tracking">
                    {`
                                !function(f,b,e,v,n,t,s)
                                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                                n.queue=[];t=b.createElement(e);t.async=!0;
                                t.src=v;s=b.getElementsByTagName(e)[0];
                                s.parentNode.insertBefore(t,s)}(window,document,'script',
                                'https://connect.facebook.net/en_US/fbevents.js');
                                fbq('init', '${config.FACEBOOK_APP_ID}');
                                fbq('track', '${FacebookEventType.PageView}');
                `}
                </Script>
                <noscript
                    dangerouslySetInnerHTML={{
                        __html: `
                        <img height="1" width="1"
                        src="https://www.facebook.com/tr?id=${config.FACEBOOK_APP_ID}&ev=PageView&noscript=1"/>
                        `,
                    }}
                />
            </>
        )}
    </>
)
