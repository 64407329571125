import { GlobalStyle } from '@assets/styles/baseStyles'
import { RTL_Style } from '@assets/styles/rtlStyles'
import { pageview } from '@util/ga'
import type { AppProps, NextWebVitalsMetric } from 'next/app'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { useCallback, useEffect } from 'react'
import 'swiper/css'
import 'swiper/css/bundle'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import 'src/assets/styles/cancellationModalStyles.css'
import 'react-toastify/dist/ReactToastify.min.css'
import nextI18NextConfig from '../../next-i18next.config.js'
import { ContextProvider, jitsuClient } from '@contexts/appContext'
import Head from 'next/head'
import { AvailableTrackingScripts, LogLabels, LogTypes } from '@util/enums'
import debounce from 'lodash/debounce'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback } from '@components/ErrorFallback'
import { useLog } from '@hooks/useLog'
import { IS_QA_ENV, isProd, isRTLLocale } from '@util/config'
import { TrackingScripts } from '@global/TrackingScripts'
import { appWithTranslation } from 'next-i18next'
import { ToastContainer } from 'react-toastify'
import { AppWithoutLayout } from '@components/layout/AppWithoutLayout'
import { QueryClient, QueryClientProvider } from 'react-query'

export function reportWebVitals({ label, value, name, id }: NextWebVitalsMetric) {
    jitsuClient.track('web_vital_metric_received', { label, value, name, id, app: 'daily-ui' })
}

function App({ Component, pageProps }: AppProps) {
    const { locale, events } = useRouter()
    const logger = useLog()
    const enabledTrackingScripts = [
        ...(isProd
            ? [AvailableTrackingScripts.HOTJAR, AvailableTrackingScripts.GA]
            : IS_QA_ENV
            ? [AvailableTrackingScripts.GA]
            : []),
    ]
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchInterval: false,
            },
        },
    })

    const globalErrorHandling = useCallback(
        (error: Error, info: { componentStack: string }) => {
            logger(
                `Uncaught error. Error: ${JSON.stringify(error.message)}, Stack: ${info.componentStack}`,
                LogTypes.error,
                LogLabels.GlobalErrorHandling,
            )
        },
        [logger],
    )

    const debouncedErrorHandling = debounce(globalErrorHandling, 1000)

    useEffect(() => {
        events.on('routeChangeComplete', (pagePath: string) => {
            pageview(pagePath)
        })
        return () => {
            events.off('routeChangeComplete', (pagePath: string) => {
                pageview(pagePath)
            })
        }
    }, [events])

    useEffect(() => {
        const dir = isRTLLocale(locale) ? 'rtl' : 'ltr'
        const lang = locale || 'en'
        document?.querySelector('html')?.setAttribute('dir', dir)
        document?.querySelector('html')?.setAttribute('lang', lang)
    }, [locale])

    return (
        <>
            <Head>
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0" />
                <title>Swapp</title>
            </Head>
            <GlobalStyle />
            <RTL_Style />

            <Script src="https://cdn.checkout.com/js/framesv2.min.js" />

            <TrackingScripts enabledTrackingScripts={enabledTrackingScripts} />
            <QueryClientProvider client={queryClient}>
                <ContextProvider>
                    <ErrorBoundary FallbackComponent={ErrorFallback} onError={debouncedErrorHandling}>
                        <Component {...pageProps} />
                    </ErrorBoundary>
                </ContextProvider>
            </QueryClientProvider>
            <ToastContainer theme="colored" position="top-right" hideProgressBar autoClose={10000} />
            <div id="portal-root" />
        </>
    )
}

export default appWithTranslation(({ Component, pageProps, router }: AppProps) => {
    if (pageProps?.noLayout) return AppWithoutLayout({ Component, pageProps, router })
    return App({ Component, pageProps, router })
}, nextI18NextConfig as any)
