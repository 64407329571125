import styled, { css } from 'styled-components'
import { BarSpinner } from './icons/SpinnerBar'
import { FC } from 'react'
import { size } from '@util/responsive'

type ButtonProps = {
    text: string
    disabled?: boolean
    mobileFix?: boolean
    noMargin?: boolean
    noPadding?: boolean
    outlined?: boolean
    padding?: string
    onClick?: () => any
    buttonType?: 'button' | 'submit' | 'reset'
    loading?: boolean
    isLink?: boolean
    isDisabled?: boolean
    desktopWidthInPx?: number
    bgColor?: string
    textColor?: string
    width100?: boolean
    dataTestId?: string
}

export const ContinueActionButton: FC<ButtonProps> = ({
    text,
    mobileFix = false,
    disabled = false,
    noMargin = false,
    noPadding = false,
    outlined = false,
    isLink = false,
    padding = '1rem',
    onClick = () => null,
    buttonType = 'submit',
    loading = false,
    desktopWidthInPx,
    bgColor = 'var(--primaryColor)',
    textColor = 'white',
    width100 = false,
    dataTestId,
}) => {
    return (
        <ButtonWrapper
            width100={width100}
            mobileFix={mobileFix}
            noMargin={noMargin}
            noPadding={noPadding}
            desktopWidth={desktopWidthInPx}
        >
            <ActionButton
                type={buttonType}
                outlined={outlined}
                isDisabled={disabled}
                isLink={isLink}
                padding={padding}
                onClick={onClick}
                disabled={disabled}
                bgColor={bgColor}
                textColor={textColor}
                data-testid={dataTestId}
            >
                {loading && <BarSpinner color="#fff" height={20} />}
                {text}
            </ActionButton>
        </ButtonWrapper>
    )
}

const handleStyle = (property) => {
    if (property.isDisabled) {
        return 'background: #E5E5E5; color: rgba(0, 0, 0, 0.2); border: none'
    } else if (property.outlined) {
        return 'background: white; color: var(--primaryColor); border: 1px solid var(--primaryColor)'
    } else if (property.isLink) {
        return 'background: white; color: var(--primaryColor); border: none'
    } else {
        return `background: ${property.bgColor}; color: ${property.textColor}; border: none`
    }
}

const ActionButton = styled.button<{
    bgColor: string
    textColor: string
    isDisabled?: boolean
    outlined?: boolean
    padding?: string
    isLink?: boolean
}>`
    ${(props) => handleStyle(props)};
    border-radius: 100px;
    font-size: var(--size-16);
    font-weight: var(--weight-bold);
    ${(props) => props.padding && `padding: ${props.padding};`}
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
`

const ButtonWrapper = styled.div<{
    mobileFix?: boolean
    noMargin?: boolean
    noPadding?: boolean
    desktopWidth?: number
    width100?: boolean
}>`
    ${({ desktopWidth }) => desktopWidth && `width: ${desktopWidth}px`};
    @media (max-width: ${size.laptop}) {
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        width: ${({ width100 }) => (width100 ? '100%' : 'auto')};
        z-index: 10;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 10px 10px;
        ${({ mobileFix }) =>
            mobileFix &&
            css`
                position: relative;
                padding: 20px 0;
                margin-bottom: 1rem;
            `}
        ${({ noMargin }) => noMargin && 'margin: 0;'}
        ${({ noPadding }) => noPadding && 'padding: 0;'}
    }
`
